import {Component, Injector, Input, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {tap} from 'rxjs/operators';
import {CatalogRecord, ConfirmationDialogComponent, Environment, FavoriteService} from '@raven';
import {snapshot} from '@store/store.helpers';
import {OrganizationSelectors} from '@store/store.selectors';
import {BowkerCoverImageSource} from '../../content-enhancement/bowker-cover-image-source';
import {ChiliFreshCoverImageSource} from '../../content-enhancement/chili-fresh-cover-image-source';
import {Store} from '@ngrx/store';

@Component({
  selector: 'rn-book-cover',
  templateUrl: './book-cover.component.html',
  styles: [
    `
        :host {
            display: inline-block;
            position: relative;
        }

        a,
        img,
        rn-fallback-image {
            width: 100%;
            height: 100%;
            /*// Leave this here for now - I 'm not sure which of these is "best" / / TODO figure out book-cover image sizing pass-through strategy / / width: auto;*/
            /*height: auto;*/
        }

        rn-fallback-image {
            display: flex;
        }

        .favorite-control {
            position: absolute;
            top: 14px;
            right: 14px;
            background-color: #fff;
            padding: 3px;
            border-radius: 50%;
        }

        .favorite-heart {
            cursor: pointer;
            vertical-align: middle;
            color: #000;
        }

        .fav {
            background-color: white;
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            border: 1px solid red;
        }
    `,
  ],
})
export class BookCoverComponent implements OnInit {
  @Input() catalogRecord: CatalogRecord;
  @Input() showFavoriteStatus = true;
  imageUrls: string[];

  constructor(private injector: Injector,
              private dialog: MatDialog,
              private environment: Environment,
              private store: Store,
              public favoriteService: FavoriteService) {
  }

  ngOnInit() {
    const coverImageStrategy = snapshot(OrganizationSelectors.Auth.coverImageSource, this.store) === 'BOWKER' ?
      new BowkerCoverImageSource(this.environment) :
      new ChiliFreshCoverImageSource();
    this.imageUrls = CatalogRecord.getImageUrls(this.catalogRecord, coverImageStrategy);
  }

  confirm(title: string, message: string, callback: () => void): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {title: title, message: message},
      })
      .afterClosed()
      .pipe(
        tap((confirm) => {
          console.log(confirm);
          if (confirm) {
            callback();
          }
        })
      )
      .subscribe();
  }

  addFavorite(): void {
    this.favoriteService.addFavorite(this.catalogRecord.id).subscribe();
  }

  deleteFavorite(): void {
    this.confirm(
      `Favorites`,
      'Are you sure you want to un-favorite this item?',
      () => {
        this.favoriteService.deleteFavorite(this.catalogRecord.id).subscribe();
      }
    );
  }
}
