<div class="submit-request-wrapper">
  <ng-container *ngTemplateOutlet="header"></ng-container>
  <div class="body">
    <ng-container *ngTemplateOutlet="importantNote"></ng-container>
    <ng-container *ngTemplateOutlet="requestFormTemplate"></ng-container>
  </div>
</div>

<ng-template #header>
  <div class="header">
    <div class="dialog-close-row flex-row flex-center-space-between">
      <h3>Item Request</h3>
      <mat-icon style="margin-bottom: 16px;" class="pointer" (click)="cancel()">close</mat-icon>
    </div>
    <span>Name: {{ patronSelectors.firstName | select }} {{ patronSelectors.lastName | select }}</span>
  </div>
</ng-template>

<ng-template #importantNote>
  <div class="important-note flex-row">
    <div class="info-icon">
      <em class="ri-information-line" matTooltip="THIS IS IMPORTANT"></em>
    </div>
    <div class="disclaimer">
      Note: Patrons may submit up to 3 item requests per month.
    </div>
  </div>
</ng-template>

<ng-template #requestFormTemplate>
  <form [formGroup]="requestForm" class="request-form">
    <div class="flex-row-col-responsive flex-center-space-between">
      <div class="request-form-item request-form-title-row">
        <mat-label>Item Title*</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="itemTitle" placeholder="Title">
        </mat-form-field>
      </div>
    </div>

    <div class="flex-row-col-responsive flex-center-space-between">
      <div class="request-form-item">
        <mat-label>Author*</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="author" placeholder="Author">
        </mat-form-field>
      </div>
      <div class="request-form-item">
        <mat-label>ISBN (not required)</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="isbn" placeholder="ISBN">
        </mat-form-field>
      </div>
    </div>
    <div class="flex-row-col-responsive flex-center-space-between">
      <div class="request-form-item">
        <mat-label>Material Type</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="materialTypeDesc" placeholder="Material Type">
        </mat-form-field>
      </div>
      <div class="request-form-item">
        <mat-label>Location for Item Pickup*</mat-label>
        <mat-form-field appearance="outline">
          <mat-select type="text" formControlName="pickupLocation" placeholder="Location">
            <mat-option *ngFor="let option of (branchSelectors.branches | select)" [value]="option.id">{{ option.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
    Notes
    <mat-form-field appearance="outline">
      <mat-label>Add any helpful notes about your request (i.e. Item Format, Publication Date, Edition, etc.)</mat-label>
      <textarea matInput formControlName="notes"></textarea>
    </mat-form-field>

    <mat-checkbox formControlName="agreeToPolicies">
      I agree that I have searched the catalog and it is not in the library's system already
    </mat-checkbox>

    <div class="button-row flex-row flex-center-end">
      <button mat-stroked-button class="save-button-group button-cancel" (click)="cancel()" data-cy="on-hold-button">
        Cancel
      </button>
      <button mat-raised-button color="primary" class="save-button-group" mat-dialog-close="true"
              [disabled]="!requestForm.valid" (click)="submit()">
        <span>Submit</span>
      </button>
    </div>
  </form>
</ng-template>
