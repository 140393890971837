import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'rn-favorite-dialog',
  template: `
    <div mat-dialog-content class="dialog">
      <h3>
        {{ !submitted ? 'Reset Password' : 'Check your email' }}
      </h3>
      <div>
        <form *ngIf="!submitted" [formGroup]="emailForm">
          <div style="margin-bottom: 16px">Get instructions sent to this email that explain how to reset your password</div>
          <mat-label>Email address*</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="email" formControlName="email" name="email" required maxlength="256" placeholder="Enter Email address">
            <mat-error *ngIf="emailForm.get('email').invalid">
              <ng-container>Please enter a valid email</ng-container>
            </mat-error>
          </mat-form-field>
          <button *ngIf="!submitted" mat-raised-button color="primary" class="cta-button"
                  [disabled]="emailForm.invalid"
                  (click)="forgotPassword()">Reset Password
          </button>
        </form>
        <ng-container *ngIf="submitted">
          <span>Follow the instructions sent to {{ email }} to reset your password</span>
          <button *ngIf="submitted" mat-raised-button color="primary" class="cta-button"
                  [disabled]="emailForm.invalid"
                  (click)="closeDialog()">Close
          </button>
        </ng-container>
      </div>
    </div>
  `,
  styles: [`
      .dialog {
          max-width: 320px;
      }

      .cta-button {
          width: min(325px, 100%);
          height: 65px;
          margin: 16px 0 0 0;
          padding: 1px 0 0;
          color: white;
          font-family: Maax, Roboto, Helvetica Neue, sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.88;
          letter-spacing: normal;
          text-align: center;
      }
  `]
})
export class ForgotPasswordDialogComponent implements OnInit {
  emailForm: FormGroup
  submitted = false;
  email: string;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { email: string }
  ) {
  }

  ngOnInit() {
    this.emailForm = this.fb.group(({
      email: [this.data.email ? this.data.email : '', {validators: [Validators.email]}],
    }));
  }

  async forgotPassword() {
    const email = this.emailForm.value.email;
    if (this.emailForm.get('email').valid) {
      this.email = email;
      // const sent = await this.authService.sendPasswordReset(email);
      // if (sent) {
      //   this.submitted = true;
      // } else {
      //   this.emailForm.controls['email'].setValue('');
      // }
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
