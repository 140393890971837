import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {OrganizationSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-contact-us-dialog',
  template: `
    <mat-dialog-content class="dialog">
      <h2 mat-dialog-title>Contact Us</h2>
      Call a librarian at {{ organizationSelectors.phoneNumber | select }}
      <br/>
      <br/>
      Or, email us at <a href="mailto:{{organizationSelectors.contactEmail | select}}">{{ organizationSelectors.contactEmail | select }}</a>
      <mat-card-actions class="flex-row">
        <button mat-dialog-close="true" mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button">
          Close
        </button>
      </mat-card-actions>
    </mat-dialog-content>
  `,
  styles: [
    `
        .mat-dialog-content {
            max-width: 320px;
            padding: 40px 10vw 30px;
            font-family: Maax, Roboto, Franklin Gothic Medium, Tahoma, sans-serif;

            h2 {
                font-family: P22Mackinac-Medium_6;
            }
        }

        mat-card-actions {
            margin-top: 60px;
        }
    `,
  ],
})
export class ContactUsDialogComponent {
  public readonly organizationSelectors = OrganizationSelectors.Auth;

  constructor(
    public dialogRef: MatDialogRef<ContactUsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      confirmButton: string;
      cancelButton: string;
    }
  ) {
  }
}
