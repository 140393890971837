import {Component, inject, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {ForgotPasswordDialogComponent} from './forgot-password-dialog.component';
import {PatronActions} from '@store/patron/patron.actions';
import {CreateAccountDialogComponent} from '../index/create-account.dialog.component';

@Component({
  selector: 'rn-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',]
})
export class LoginComponent {
  private dialog = inject(MatDialog);
  private readonly fb: FormBuilder = inject(FormBuilder);
  private readonly router: Router = inject(Router);
  private readonly store: Store = inject(Store);
  loginForm: FormGroup = this.fb.group(({
    email: ['', {validators: [Validators.email]}],
    password: ['', {validators: [Validators.required]}]
  }));

  constructor(@Optional() public dialogRef: MatDialogRef<LoginComponent>) {
  }

  login() {
    this.store.dispatch(PatronActions.loginPatron(this.loginForm.value.email, this.loginForm.value.password));
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    // else {
    //   this.authService.redirectUrl && console.log('Logging in, redirect to: ', this.authService.redirectUrl);
    //   await this.router.navigateByUrl(this.authService.redirectUrl || '/catalog');
    // }
  }

  onClickCreateAccount(): void {
    this.dialog.open(CreateAccountDialogComponent);
  }

  forgotPassword(): void {
    const email = this.loginForm.get('email').valid ? this.loginForm.value.email : '';
    this.dialog.open(ForgotPasswordDialogComponent, {data: {email: email}, maxWidth: '90vw'});
  }
}
