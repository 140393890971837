<div class="flex-row flex-start-start">
  <div class="flex-1">
    <form [formGroup]="emailForm" novalidate class="registration-form">
      <div class="mat-display-2 registration-title">{{ forLookupContext ? 'Enter Your Email' : 'Create Account' }}</div>
      <h4 class="registration-description">Please provide your email address. This will be used as your username.</h4>
      <rv-input-form-field fieldName="email" [externalLabel]="true" overrideLabel="Email" [formGroup]="emailForm"/>
      <mat-error *ngIf="emailField.hasError('patronEmailUnavailable')">
        An account with this email already exists. Go <a href="/login">here</a> to log-in
      </mat-error>
      <button mat-raised-button color="primary" class="next-button" (click)="onClickNext()" [disabled]="!emailForm.valid">Next</button>
    </form>
  </div>
  <div class="{{forLookupContext ? 'verify-email-img' : 'create-account-img'}} flex-1"></div>
</div>
