import {Component, OnInit} from '@angular/core';
import {Location as BrowserLocation} from '@angular/common';
import {FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {Observable, ReplaySubject, tap} from 'rxjs';
import {PATRON_NOTIFICATION_PREFERENCE_TYPE, PatronNotificationPreference, RoutesService} from '@raven';
import {CommunicationPreferences} from '../../model/patron/communication-preferences';
import {getErrorMessage} from '../lib/field-error/field-error.helper';
import {PatronSelectors} from '@store/store.selectors';
import {snapshot} from '@store/store.helpers';
import {Patron} from '@store/patron/patron.types';
import {PatronService} from '../../services/patron.service';

@Component({
  selector: 'rn-notification-preferences',
  templateUrl: './notification-preferences.component.html',
  styleUrls: ['./notification-preferences.component.scss'],
})
export class NotificationPreferencesComponent implements OnInit {

  patron: Patron = snapshot(PatronSelectors.Auth.patron);
  notificationPreferencesForm: FormGroup;
  getErrorMessage = getErrorMessage;
  preferences$: Observable<CommunicationPreferences>;
  destroyed$ = new ReplaySubject<boolean>(1);
  NOTIFICATION_PREFERENCE_SELECTION = [
    {label: 'Email', value: 'email'},
    {label: 'SMS Text', value: 'sms'},
    {label: 'Both Email and SMS Text', value: 'both'},
  ];

  constructor(
    public routingService: RoutesService,
    private browserLocation: BrowserLocation,
    private fb: FormBuilder,
    private patronService: PatronService
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.preferences$ = this.patronService.getCommunicationPreferences().pipe(
      tap((preferences) => {
        this.notificationPreferencesForm.controls['accountNotifications'].setValue(
          this.getSelectOption(this.findNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.ACCOUNT)), {emitEvent: true});
        this.notificationPreferencesForm.controls['receiptNotifications'].setValue(
          this.getSelectOption(this.findNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.ACCOUNT)), {emitEvent: true});
        this.notificationPreferencesForm.controls['renewNotifications'].setValue(
          this.getSelectOption(this.findNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.ACCOUNT)), {emitEvent: true});
        this.notificationPreferencesForm.controls['expirationNotifications'].setValue(
          this.getSelectOption(this.findNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.ACCOUNT)), {emitEvent: true});
        // const localEventNotification = this.getNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.LOCAL_EVENTS);
        // this.notificationPreferencesForm.controls['localEvents'].setValue(this.getSelectOption(localEventNotification), {emitEvent: true});
        // this.notificationPreferencesForm.controls['localEventsToggle'].setValue(localEventNotification.sendNotification, {emitEvent: true});
        // const monthlyNewsletterNotification = this.getNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.MONTHLY_NEWSLETTER);
        // this.notificationPreferencesForm.controls['monthlyNewsletter'].setValue(this.getSelectOption(monthlyNewsletterNotification), {emitEvent: true});
        // this.notificationPreferencesForm.controls['monthlyNewsletterToggle'].setValue(monthlyNewsletterNotification.sendNotification, {emitEvent: true});
      }));
  }

  findNotification(notifications: PatronNotificationPreference[], type: PATRON_NOTIFICATION_PREFERENCE_TYPE): PatronNotificationPreference {
    if (notifications) {
      for (const notification of notifications) {
        if (notification.notificationType == type) {
          return notification;
        }
      }
    }
    // default value if no preferences exist
    return new PatronNotificationPreference();
  }

  getSelectOption(notification: PatronNotificationPreference) {
    if (notification.notifyEmail && notification.notifyText) {
      return 'both';
    }
    return notification.notifyText ? 'sms' : 'email';
  }

  createForm(): void {
    this.notificationPreferencesForm = this.fb.group({
      notificationEmail: [this.patron.notificationEmail || this.patron.email, [Validators.email, Validators.maxLength(256)]],
      phone: [{value: this.patron.phone1, disabled: true}, []],
      accountNotifications: ['email', []],
      receiptNotifications: ['email', []],
      renewNotifications: ['email', []],
      expirationNotifications: ['email', []],
      // localEvents: ['email', []],
      // localEventsToggle: [true, []],
      // monthlyNewsletter: ['email', []],
      // monthlyNewsletterToggle: [true, []],
    });
  }

  cancel(): void {
    // head back to whence we came
    this.browserLocation.back();
  }

  selectionToNotificationPreference(type: PATRON_NOTIFICATION_PREFERENCE_TYPE, selectValue: string, toggle = true): PatronNotificationPreference {
    return {
      patronId: this.patron.id,
      notificationType: type,
      sendNotification: toggle,
      notifyEmail: selectValue === 'both' || selectValue === 'email',
      notifyText: selectValue === 'both' || selectValue === 'sms',
      notifyPhone: false,
    }
  }

  submit(): void {
    const formValues = this.notificationPreferencesForm.getRawValue();
    const notifications = [
      this.selectionToNotificationPreference(PATRON_NOTIFICATION_PREFERENCE_TYPE.ACCOUNT, formValues.accountNotifications),
      this.selectionToNotificationPreference(PATRON_NOTIFICATION_PREFERENCE_TYPE.RECEIPT, formValues.receiptNotifications),
      this.selectionToNotificationPreference(PATRON_NOTIFICATION_PREFERENCE_TYPE.AUTO_RENEW, formValues.renewNotifications),
      this.selectionToNotificationPreference(PATRON_NOTIFICATION_PREFERENCE_TYPE.CARD_EXPIRE, formValues.expirationNotifications),
    ];
    const preferences: CommunicationPreferences = {
      notifications: notifications,
      notificationEmail: formValues.notificationEmail,
      phoneNumber: this.patron.phone1,
      unsubscribeAll: false
    };
    this.patronService
      .updateCommunicationPreferences(preferences, {
        successMessage: 'Preferences updated Successfully.',
        errorMessage: 'Error updating your communication preferences. Please contact customer support.',
        onSuccess: () => {
          this.notificationPreferencesForm.markAsPristine();
          this.notificationPreferencesForm.markAsUntouched();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
