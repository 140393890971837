import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {PatronActions} from '@store/patron/patron.actions';
import {Store} from '@ngrx/store';

@Component({
  selector: 'rn-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  message: string;

  constructor(
    public router: Router,
    private snackBar: MatSnackBar,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    // log the person out
    this.store.dispatch(PatronActions.logoutPatron());
  }
}
