<div class="page-body-wrapper" [rvIsLoading]="listDataService.loading">
  <ng-container *ngIf="displayMode$ | async as displayMode">
    <ng-container *ngTemplateOutlet="header; context: { totalItemCount: listDataService.totalCount, displayMode: displayMode }">
    </ng-container>
    <ng-container *ngIf="listDataService.data as items">
      <ng-container *ngIf="items.length > 0; else empty">
        <ng-container *ngTemplateOutlet="(displayMode$ | async) === 'grid' ? grid : list; context: { items: items }">
        </ng-container>
      </ng-container>
    </ng-container>
    <rn-pager></rn-pager>
  </ng-container>
  <ng-container *ngTemplateOutlet="commandBar"></ng-container>
</div>

<ng-template #empty>
  <div class="empty">No favorites</div>
</ng-template>

<ng-template #header let-itemCount="totalItemCount" let-displayMode="displayMode">
  <div class="grid-header">
    <div class="mat-display-1">Favorites ({{ itemCount || 0 }})</div>
    <div class="grid-controls">
      <rn-grid-list-select [displayMode]="displayMode" (displayModeChange)="setDisplayMode($event)">
      </rn-grid-list-select>
      <rn-favorites-page-size-select></rn-favorites-page-size-select>
    </div>
  </div>
</ng-template>

<ng-template #grid let-items="items">
  <rn-favorites-grid [items]="items"></rn-favorites-grid>
</ng-template>

<ng-template #list let-items="items">
  <div class="items item-list">
    <ng-container *ngFor="let item of items; let first = first">
      <rn-list-item [catalogRecord]="item" [topBorder]="first"></rn-list-item>
    </ng-container>
  </div>
</ng-template>

<ng-template #commandBar>
  <div class="clear-for-footer"></div> <!-- clear space for the fixed footer -->
  <div class="save-footer flex-row flex-center-center" style="height: 100px; background-color: white">
    <div class="button-row-right" style="max-width: var(--content-width); width: 100%;">
      <button id="clearFavoritesBtn" mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button"
              [disabled]="!listDataService.data.length" (click)="clearFavorites()">
        Clear all Favorites
      </button>
    </div>
  </div>
</ng-template>
