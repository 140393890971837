import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, EMPTY} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NotificationService, PatronVerificationService} from '@raven';
import {authenticated} from '@store/patron/patron.selectors';
import {Store} from '@ngrx/store';

@Component({
  selector: 'rn-verification',
  templateUrl: './verification-email.component.html',
})
export class VerificationEmailComponent implements OnInit {
  patronId: number;
  verificationKey: string;

  // indicate isLoading state
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private patronVerificationService: PatronVerificationService,
              private route: ActivatedRoute,
              private router: Router,
              private notificationService: NotificationService,
              private store: Store) {
  }

  ngOnInit(): void {
    // grab the verification key from the url
    this.patronId = +this.route.snapshot.queryParamMap.get('slug');
    this.verificationKey =
      this.route.snapshot.queryParamMap.get('verification_key');
    console.log('the patron id is', this.patronId);
    console.log('the verificationKey id is', this.verificationKey);
    // try to verify the email
    this.verifyEmail(this.verificationKey);
  }

  verifyEmail(verificationKey: string): void {
    // begin isLoading
    this.loadingSubject.next(true);
    // make the call to the server
    this.patronVerificationService
      .verifyEmail({
        patronId: this.patronId,
        type: 'EMAIL',
        code: verificationKey,
      })
      .pipe(
        catchError(() => {
          this.notificationService.showSnackbarError(
            'Error verifying email. Please try again or contact customer support help.'
          );
          // send to verification page
          this.router.navigate(['/register']);
          // window.location.href = '/register';
          return EMPTY;
        })
      )
      .subscribe(() => {
        console.log('email verified. checking for patron');
        if (authenticated(this.store)) {
          // force a full refresh to re-initialize the patron state now that email is verified
          window.location.href = `/register`;
        } else {
          this.notificationService.showSnackbarSuccess(
            'Your email has been successfully verified. Please log in to continue.'
          );
          this.router.navigate(['/login']);
        }
      });
  }
}
