import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExpansionPanelComponent} from './expansion-panel/expansion-panel.component';
import {BackgroundAccentComponent} from './background-accent/background-accent.component';
import {BookCarouselComponent} from './book-carousel.component';
import {GridCarouselComponent} from './grid-carousel.component';
import {FallbackImageComponent} from './fallback-image/fallback-image.component';
import {BookCoverComponent} from '../favorites/book-cover/book-cover.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MatNativeDateModule, MatRippleModule,} from '@angular/material/core';
import {MatLegacyOptionModule as MatOptionModule,} from '@angular/material/legacy-core';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CustomPluralPipe} from './pipes/plural.pipe';
import {MaterialTypeIconComponent} from './material-type-icon/material-type-icon.component';
import {GridItemComponent} from './grid-item/grid-item.component';
import {ListItemComponent} from './list-item/list-item.component';
import {BarcodeComponent} from './barcode.component';
import {
  CarouselComponent,
  CarouselItemDirective,
  ConfirmationDialogComponent,
  IsLoadingDirective,
  LoadingTemplateDirective,
  LoadingWrapperComponent,
  NonBreakingPipe,
  PagerComponent,
  SkeletonTableComponent,
} from '@raven';
import {AccountStatusWarningComponent} from '../alerts/account-status-warning.component';
import {SelectPipeModule} from './pipes/select-pipe.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [
    ExpansionPanelComponent,
    BackgroundAccentComponent,
    BookCarouselComponent,
    GridCarouselComponent,
    FallbackImageComponent,
    BookCoverComponent,
    ConfirmationDialogComponent,
    NonBreakingPipe,
    CarouselItemDirective,
    CarouselComponent,
    CustomPluralPipe,
    PagerComponent,
    MaterialTypeIconComponent,
    GridItemComponent,
    ListItemComponent,
    BarcodeComponent,
    LoadingTemplateDirective,
    IsLoadingDirective,
    LoadingWrapperComponent,
    SkeletonTableComponent,
    AccountStatusWarningComponent,
  ],
  exports: [
    SelectPipeModule,
    ExpansionPanelComponent,
    BackgroundAccentComponent,
    BookCarouselComponent,
    GridCarouselComponent,
    FallbackImageComponent,
    BookCoverComponent,
    ConfirmationDialogComponent,
    NonBreakingPipe,
    CarouselItemDirective,
    CarouselComponent,
    CustomPluralPipe,
    PagerComponent,
    // angular
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    // angular material stuff
    MatNativeDateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxChartsModule,
    // CDKs
    DragDropModule,
    // Forms
    MaterialTypeIconComponent,
    GridItemComponent,
    ListItemComponent,
    BarcodeComponent,
    LoadingTemplateDirective,
    IsLoadingDirective,
    LoadingWrapperComponent,
    SkeletonTableComponent,
    AccountStatusWarningComponent,
  ],
  imports: [
    SelectPipeModule,
    // angular
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    // angular material stuff
    MatNativeDateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxChartsModule,
    // CDKs
    DragDropModule,
  ],
})
export class SharedModule {
}
