<div class="flex-row flex-start-start">
  <div class="flex-1">
    <form [formGroup]="detailsForm" novalidate class="registration-form">
      <div class="mat-display-2 registration-title">Details</div>
      <h4 class="registration-description">Please enter your personal details for your library account.</h4>
      <div class="flex-row flex-gap-16">
        <div class="flex-col">
          <rv-input-form-field fieldName="firstName" [externalLabel]="true" overrideLabel="First Name"
                               [formGroup]="detailsForm" subscriptSizing="fixed"/>
          <rv-input-form-field fieldName="lastName" [externalLabel]="true" overrideLabel="Last Name"
                               [formGroup]="detailsForm" subscriptSizing="fixed"/>
          <rv-date-form-field fieldName="dateOfBirth" [externalLabel]="true" overrideLabel="Date of Birth"
                              [formGroup]="detailsForm" subscriptSizing="fixed"/>
        </div>
        <div class="flex-col">
          <rv-input-form-field fieldName="address" [externalLabel]="true" overrideLabel="Street Address"
                               [formGroup]="detailsForm" subscriptSizing="fixed"/>
          <div class="flex-row flex-gap-12">
            <rv-input-form-field fieldName="city" [externalLabel]="true" overrideLabel="City"
                                 [formGroup]="detailsForm" subscriptSizing="fixed" class="city-field"/>
            <rv-select-form-field fieldName="state" [externalLabel]="true" overrideLabel="State"
                                  [formGroup]="detailsForm" subscriptSizing="fixed" class="state-field"
                                  [options]="STATE_SELECT" [includeUndefinedOption]="false"/>
            <rv-input-form-field fieldName="zip" [externalLabel]="true" overrideLabel="Zip Code"
                                 [formGroup]="detailsForm" subscriptSizing="fixed" class="zip-field"/>
          </div>
          <rv-select-form-field fieldName="branchId" [externalLabel]="true" overrideLabel="Pick-up Location"
                                [formGroup]="detailsForm" subscriptSizing="fixed"
                                [options]="branchSelectors.branchesSelectOptions | select" [includeUndefinedOption]="false"/>
        </div>
      </div>
      <div class="flex-row flex-start-end">
        <button mat-raised-button color="primary" class="next-button" (click)="onClickNext()" [disabled]="!detailsForm.valid">Next</button>
      </div>
    </form>
  </div>
  <div class="create-password-img flex-1"></div>
</div>
