import {Component} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SubSink} from 'subsink';
import {RoutesService, TermsComponent} from '@raven';
import {BranchSelectors, OrganizationSelectors, PatronSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-catalog-footer',
  templateUrl: './catalog-footer.component.html',
  styleUrls: ['./catalog-footer.component.scss'],
})
export class CatalogFooterComponent {
  public readonly organizationSelectors = OrganizationSelectors.Auth;
  public readonly patronSelectors = PatronSelectors.Auth;
  public readonly branchSelectors = BranchSelectors.Auth;
  private subs = new SubSink();

  constructor(
    public routingService: RoutesService,
    private dialog: MatDialog
  ) {
  }

  termsAndConditions(): void {
    this.dialog.open(TermsComponent, {
      data: 'Terms and Conditions',
    });
  }

  privacyPolicy(): void {
    this.dialog.open(TermsComponent, {
      data: 'Privacy Policy',
    });
  }

  cookiesDialog(): void {
    this.dialog.open(TermsComponent, {
      data: 'Cookies Policy',
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
