import {Component} from '@angular/core';

@Component({
  selector: 'rn-registration-complete',
  template: `
    <div class="flex-row flex-start-center">
      <div class="registration-form">
        <div class="mat-display-2 registration-title">Success!</div>
        <h4 class="registration-description">Welcome to Lincoln Township Public Library. You now have full access to digital resources as well as our extensive
          collection of books, DVDs, CDs and more!
          <br/><br/>
          What would you like to do first?</h4>
        <a mat-raised-button color="primary" class="next-button browse-button" routerLink="/catalog">Browse Catalog</a>
        <a mat-raised-button class="next-button view-account-button" routerLink="/profile#account">View Account</a>
      </div>
    </div>
  `,
  styles: [`
      .registration-title {
          margin-bottom: 0;
      }

      .registration-form {
          width: 387px;
      }

      .registration-description {
          margin: 28px 0;
      }

      .next-button {
          width: min(100%, 325px);
          height: 65px;
          margin: 10px 0 10px 0;
          padding: 1px 0 0;
          font-family: Maax, Roboto, Helvetica Neue, sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.88;
          letter-spacing: normal;
          text-align: center;
      }

      .browse-button:not(:disabled) {
          color: white;
          background-color: var(--active);
      }

      .view-account-button:not(:disabled) {
          border: 1px solid var(--dk-gray);
          color: var(--dk-gray);
          background-color: white;
      }
  `]
})
export class RegistrationCompleteComponent {
}
