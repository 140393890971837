import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Environment} from '../model/environment';
import {CommunicationPreferences} from '../model/patron/communication-preferences';
import {ServiceOptions, ServiceUtilService} from './service-util.service';
import {ResponseBase} from '../model/response-base/response-base';
import {Patron} from '../model/patron/patron';
import {Store} from '@ngrx/store';
import {PatronActions} from '@store/patron/patron.actions';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {LoginComponent} from '../core/login/login.component';

@Injectable({providedIn: 'root'})
export class PatronService {

  constructor(
    private dialog: MatDialog,
    private environment: Environment,
    private http: HttpClient,
    private serviceUtil: ServiceUtilService,
    private store: Store,
  ) {
  }

  getCommunicationPreferences(): Observable<CommunicationPreferences> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/communication-preferences`;
    return this.serviceUtil.httpInvoker(this.http.get<ResponseBase<CommunicationPreferences>>(url))
      .pipe(map(rb => rb.objects[0]));
  }

  updateCommunicationPreferences(preferences: CommunicationPreferences, options?: ServiceOptions<ResponseBase<CommunicationPreferences>>): void {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/communication-preferences`;
    this.serviceUtil.httpInvoker(this.http.put<ResponseBase<CommunicationPreferences>>(url, JSON.stringify(preferences)), options);
  }

  updatePin(pin: string, options?: ServiceOptions<boolean>): Observable<boolean> {
    const url = `${this.environment.apiUrl}/patrons/v1/patrons/account/pin`;
    const result = this.serviceUtil.httpInvoker(this.http.patch<boolean>(url, pin), options);
    result.subscribe()
    return result;
  }

  save(patron: Patron, options?: ServiceOptions<ResponseBase<Patron>>): Observable<Patron> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons`;
    const result = this.serviceUtil.httpInvoker(this.http.put<ResponseBase<Patron>>(url, patron), options)
      .pipe(map(rb => rb.objects[0]));
    result.subscribe(() => this.updatePatronState());
    return result;
  }

  updatePatronState(): void {
    this.store.dispatch(PatronActions.reloadAuthPatron());
  }

  /**
   * Patron self renew expired account in good standing
   */
  renewAccount(options?: ServiceOptions<void>): Observable<void> {
    const url = `${this.environment.apiUrl}/patrons/v1/patrons/account/renew`;
    const result = this.serviceUtil.httpInvoker(this.http.patch<void>(url, ''), options);
    result.subscribe(() => this.updatePatronState()); // to update patron w/ new status + expiration instead of making assumptions about them in component subscription
    return result;
  }

  openLoginDialog(): void {
    this.dialog.open(LoginComponent);
  }
}
