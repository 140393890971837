import {DateOnlyIsoString, DateOnlyRangeIsoString, DateString, DateTimeIsoString} from '@store/common/common.types';
import {isDate, isDateOnlyIsoString, isDateTimeIsoString, isDefined, isPopulatedDateString} from '@store/common/typing.helpers';
import {minutesToMilliseconds, subDays} from 'date-fns';
import {format} from 'date-fns-tz';

export function toDateOnly(date: DateString): DateOnlyIsoString {
  // If it's already date-only, there's nothing to do.  If it's not a date-time string, just pass it through unchanged.
  if (isDateOnlyIsoString(date) || !isDateTimeIsoString(date)) return date;

  return (date as DateOnlyIsoString).split('T')[0];
}

/**
 * Use this instead of Date.toISOString(), because that function returns a string formatted like '2023-10-12T21:50:14.858Z', while our ISO format
 * (based on our Java API) is just '2023-10-12T21:50:14Z' (no milliseconds).
 */
export function toDateTimeIsoString(date: Date): DateTimeIsoString {
  if (!date) return '';

  return date.toISOString().replace(/\.\d{3}/, '');
}

export function toDateOnlyIsoString(date: Date): DateOnlyIsoString {
  return toDateOnly(toDateTimeIsoString(date));
}

export function toDateOnlyRangeIsoString(startDate?: Date, endDate?: Date): DateOnlyRangeIsoString {
  const startDateString: DateOnlyIsoString = isDefined(startDate) ? toDateOnlyIsoString(startDate) : '';
  const startIsPopulated: boolean = isPopulatedDateString(startDateString);

  const endDateString: DateOnlyIsoString = isDefined(endDate) ? toDateOnlyIsoString(endDate) : '';
  const endIsPopulated: boolean = isPopulatedDateString(endDateString);

  if (startIsPopulated && endIsPopulated) return `${startDateString}..${endDateString}`;
  if (startIsPopulated && !endIsPopulated) return `${startDateString}..${startDateString}`;
  if (!startIsPopulated && endIsPopulated) return `${endDateString}..${endDateString}`;

  return '';
}

/**
 * Useful for simulating a "date only" Date (which doesn't exist in JavaScript) by converting a date to midnight -- *in the browser's current time zone*.
 * See https://stackoverflow.com/a/52352512.
 * See "UNIT TESTS" below.
 *
 * And... hopefully with date-fns-tz (date-fns with added support), we can rely on it and get rid of this and a lot of our other custom date code.
 */
export function toLocalMidnight(date: Date | string): Date {
  const dateOnlyStr: string = isDate(date) ? toDateOnlyIsoString(date) : toDateOnly(date);
  const dateObject = new Date(dateOnlyStr);
  return new Date(dateObject.valueOf() + minutesToMilliseconds(dateObject.getTimezoneOffset()));
}

/** A simple proxy for new Date().  Seems silly, but Date() is difficult to mock in unit tests, so use this function that is much easier to mock. */
export function now(): Date {
  return new Date();
}

export function yesterdayAsString(): DateOnlyIsoString {
  return dayBeforeDateAsString(now());
}

export function dayBeforeDateAsString(date: Date): DateOnlyIsoString {
  // This is the date-fns-tz format(), not the standard one.  So after subtracting a day, the formatter will give us the right date in the local time zone.
  return format(subDays(date, 1), 'Y-MM-dd');
}

// "UNIT TESTS" for toLocalMidnight().  Until we have an easy way to write real, isolated unit tests:  uncomment this, and it'll run itself on browser refresh.
// (function () {
//   function testDate(header: string, date: Date): void {
//     console.group(header);
//     console.log('UTC date:', toDateTimeIsoString(date));
//     console.log('timezoneOffset:', date.getTimezoneOffset());
//     console.log('to local midnight as Date:', toDateTimeIsoString(toLocalMidnight(date)));
//     console.log('day before as string:', dayBeforeDateAsString(date));
//     console.groupEnd();
//   }
//
//   function testString(header: string, string: string): void {
//     console.group(header);
//     console.log('UTC string:', string);
//     console.log('to local midnight:', toDateTimeIsoString(toLocalMidnight(string)));
//     console.groupEnd();
//   }
//
//   {
//     console.group('toLocalMidnight() tests');
//     {
//       console.group('with Date input');
//       // Remember that Date.UTC()'s month parameter is zero-based. 🙄
//       testDate('1am UTC on January 1 - U.S. timezones should show the day before UTC', new Date(Date.UTC(2024, 0, 1, 1, 0, 0)));
//       testDate('1pm UTC on January 1 - U.S. timezones should show the same day as UTC', new Date(Date.UTC(2024, 0, 1, 13, 0, 0)));
//       testDate('1am UTC on June 1 - U.S. timezones should show the day before UTC', new Date(Date.UTC(2024, 5, 1, 1, 0, 0)));
//       testDate('1pm UTC on June 1 - U.S. timezones should show the same day as UTC', new Date(Date.UTC(2024, 5, 1, 13, 0, 0)));
//       testDate('now() - depends on when you run this!', now());
//       console.groupEnd();
//     }
//
//     {
//       console.group('with DateTimeIsoString input');
//       testString('1am UTC on January 1 - U.S. timezones should show the day before UTC', '2024-01-01T01:00:00Z');
//       testString('1pm UTC on January 1 - U.S. timezones should show the same day as UTC', '2024-01-01T13:00:00Z');
//       testString('1am UTC on June 1 - U.S. timezones should show the day before UTC', '2024-06-01T01:00:00Z');
//       testString('1pm UTC on June 1 - U.S. timezones should show the same day as UTC', '2024-06-01T13:00:00Z');
//       console.groupEnd();
//     }
//
//     {
//       console.group('with DateOnlyIsoString input');
//       testString('January 1 - U.S. timezones should show the day before UTC', '2024-01-01');
//       testString('June 1 - U.S. timezones should show the day before UTC', '2024-06-01');
//       console.groupEnd();
//     }
//     console.groupEnd();
//   }
// })();
