import {NgModule} from '@angular/core';
import {BaseFormFieldComponent} from './base-form-field/base-form-field.component';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {CheckboxFormFieldComponent} from './checkbox-form-field/checkbox-form-field.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DateFormFieldComponent} from './date-form-field/date-form-field.component';
import {FieldErrorComponent} from './field-error.component';
import {MatIconModule} from '@angular/material/icon';
import {InputFormFieldComponent} from './input-form-field/input-form-field.component';
import {PasswordFormFieldComponent} from './password-form-field/password-form-field.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {PhoneFormFieldComponent} from './phone-form-field/phone-form-field.component';
import {RadioGroupFormFieldComponent} from './radio-group-form-field/radio-group-form-field.component';
import {SelectFormFieldComponent} from './select-form-field/select-form-field.component';
import {MatSelectModule} from '@angular/material/select';
import {SelectPipeModule} from '../pipes/select-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    SelectPipeModule,
  ],
  declarations: [
    BaseFormFieldComponent,
    CheckboxFormFieldComponent,
    DateFormFieldComponent,
    FieldErrorComponent,
    InputFormFieldComponent,
    PasswordFormFieldComponent,
    PhoneFormFieldComponent,
    RadioGroupFormFieldComponent,
    SelectFormFieldComponent,
  ],
  exports: [
    CheckboxFormFieldComponent,
    DateFormFieldComponent,
    InputFormFieldComponent,
    PasswordFormFieldComponent,
    PhoneFormFieldComponent,
    RadioGroupFormFieldComponent,
    SelectFormFieldComponent,
  ],
})
export class RvFormsModule {
}
