<div class="login-container">
  <div class="login-column flex-col">
    <ng-container *ngIf="dialogRef">
      <div class="title-text">Login</div>
      <h4 class="secondary-text">Welcome back! Please enter your username and password to proceed.</h4>
    </ng-container>
    <form [formGroup]="loginForm" *ngIf="loginForm">
      <mat-label>Email*</mat-label>
      <mat-form-field appearance="outline">
        <input matInput type="email" formControlName="email" name="email" required maxlength="256" placeholder="Enter Email address">
        <mat-error *ngIf="loginForm.get('email').invalid">
          <ng-container>Please enter a valid email</ng-container>
        </mat-error>
      </mat-form-field>
      <mat-label>Password*</mat-label>
      <mat-form-field appearance="outline">
        <input matInput type="password" formControlName="password" required maxlength="256" placeholder="Enter password">
        <mat-error *ngIf="loginForm.get('password').invalid">Password is required</mat-error>
      </mat-form-field>
      <div class="mat-small forgot-password-link"><a class="pointer" (click)="forgotPassword()">Forgot password?</a></div>
      <button mat-raised-button color="primary" class="form-button form-submit-button"
              [disabled]="loginForm.invalid"
              (click)="login()">Login
      </button>
      <button mat-raised-button class="form-button create-account-button" (click)="onClickCreateAccount()">Create Account</button>
    </form>
  </div>
</div>
